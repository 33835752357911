<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
      <template v-slot:groupName>
        <div style="position: relative">
          <el-input v-model="formData.groupName" :readonly="true"></el-input>
        </div>
      </template>
      <template v-slot:PRODUCT_KEY>
        <div style="position: relative">
          <el-input v-model="formData.PRODUCT_KEY">
            <div slot="append">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                type="primary"
                @click="openRenewalDialog('PRODUCT_KEY')"
            >{{formData.PRODUCT_KEY === $t('aiAccountPermissionManage.notHave') ? $t('aiAccountPermissionManage.open') : $t('aiAccountPermissionManage.renewal')}}</el-button>
            <el-button v-if="formData.PRODUCT_KEY !== $t('aiAccountPermissionManage.notHave')" type="danger" @click="deactivate('PRODUCT_KEY')">{{$t('aiAccountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:dataSet>
        <div style="position: relative">
          <el-input v-model="formData.dataSet" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('dataSet')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:algorithmEngineering>
        <div style="position: relative">
          <el-input v-model="formData.algorithmEngineering" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('algorithmEngineering')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:aiApp>
        <div style="position: relative">
          <el-input v-model="formData.aiApp" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('aiApp')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:realTimeMonitoring>
        <div style="position: relative">
          <el-input v-model="formData.realTimeMonitoring" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('realTimeMonitoring')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:report>
        <div style="position: relative">
          <el-input v-model="formData.report" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('report')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:dataAnalysis>
        <div style="position: relative">
          <el-input v-model="formData.dataAnalysis" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree('dataAnalysis')"
            >{{$t('aiAccountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
    </dialog-form-list>
    <renewal v-if="showRenewal" :tableRow="tableRow" :renewalType="renewalType" :is-open="isOpen" @closePage="closePage" />
    <dialog-tree
        :title="title"
        v-if="showDialogMultipleTree"
        height="400px"
        :isDefine="true"
        :defaultExpandedKeys="[1]"
        :defineConfirm="true"
        :defaultContent="defaultContent"
        @cancel="showDialogMultipleTree = false"
        @confirm="changeContactsVisible"
    >
      <template v-slot:default="{ data }">
        <div
            class="treeList"
            style="display: flex; justify-content: space-between; width: 100%"
        >
          <div>
            <el-checkbox
                class="canUseRadio"
                v-model="data.selected"
                :indeterminate="data.isIndeterminate"
                @change="changeSelected($event, data, 'selected')"
            />
            {{ data.name }}
          </div>
        </div>
      </template>
    </dialog-tree>
  </div>
</template>

<script>
import Renewal from './renewal'
import {aiStationUpdateVisible} from "@/api";

export default {
  components: {
    Renewal,
  },
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      title: this.$t('aiAccountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      currentVisibleType: null,
      cmd: this.$store.state.cmd,
      formData: {},
      isOpen: false,
      config: [
        // lang:目标账号
        {
          label: this.$t('aiAccountPermissionManage.targetAccount'),
          prop: 'targetAccount',
          hidden: false,
          tagProps: {
            readonly:'readonly'
          }
        },
        // lang:所属部门
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.accountGroup'),
          prop: 'groupName',
        },
        // lang:产品密钥
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.productKey'),
          prop: 'PRODUCT_KEY',
        },
        // lang:数据集可见范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.dataSetVisible'),
          prop: 'dataSet',
        },
        // lang:数据集文件导出
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.dataSetFileExport'),
          prop: 'dataSetFileExportEnable',
        },
        // lang:数据集文件删除
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.dataSetDelete'),
          prop: 'dataSetDeleteEnable',
        },
        // lang:算法工程可见范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.algorithmEngineeringVisible'),
          prop: 'algorithmEngineering',
        },
        // lang:算法模型文件导出
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.algorithmModelFileExport'),
          prop: 'algorithmModelFileExportEnable',
        },
        // lang:算法模型文件发布
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.algorithmModelPublish'),
          prop: 'algorithmModelPublishEnable',
        },
        // lang:算法模型删除
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.algorithmModelDelete'),
          prop: 'algorithmModelDeleteEnable',
        },
        // lang:AI应用可见范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.aiAppVisible'),
          prop: 'aiApp',
        },
        // lang:AI应用发布
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.aiAppPublish'),
          prop: 'aiAppPublishEnable',
        },
        // lang:AI应用删除
        {
          tag: 'el-switch',
          label: this.$t('aiAccountPermissionManage.aiAppDelete'),
          prop: 'aiAppDeleteEnable',
        },
        // lang:实时监控可见范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.realTimeMonitoringVisible'),
          prop: 'realTimeMonitoring',
        },
        // lang:报告可见范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.reportVisible'),
          prop: 'report',
        },
        // lang:数据分析范围
        {
          tag: 'slot-content',
          label: this.$t('aiAccountPermissionManage.dataAnalysisVisible'),
          prop: 'dataAnalysis',
        },
      ],
      accountPermissionData: null,
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    deactivate(type) {
      this.$element
          .showConfirm({
            content: this.$t('aiAccountPermissionManage.deactivateConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.disabledAccount,
              data: {
                uid: this.tableRow.uid,
                type
              },
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deactivateSuccess'), 'success')
              this.formData[type] = this.$t('aiAccountPermissionManage.notHave')
            })
          })
    },
    closePage(evt) {
      this.showRenewal = false
      if(evt === 'add') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findAccountPermission,
          data: {
            uid: this.tableRow.uid,
          },
        }).then((data) => {
          let pk = data.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('aiAccountPermissionManage.notHave')
          }
          let ti = data.secretKeyDistributionList.filter(item => item.type === 'WEB_EDIT_PRODUCT_KEY')
          if(ti.length !== 0) {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$util.format(ti[0].expirationDate)
          } else {
            this.formData.WEB_EDIT_PRODUCT_KEY = this.$t('aiAccountPermissionManage.notHave')
          }
        })
      }
    },
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 弹出续期窗口
    openRenewalDialog(type) {
      this.showRenewal = true
      this.renewalType = type
      this.isOpen = this.formData[type] === this.$t('aiAccountPermissionManage.notHave')
    },
    openDialogTree(type) {
      this.currentVisibleType = type
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      this.$ajax({
        url: '/core' + this.$api.findCompanyTreeContacts,
        // url: '/core' + this.$api.findCompanyTreeContacts,
        data: {
        },
      }).then((data) => {
        console.log(data)
        let res = {accountPermission: this.accountPermissionData}
        data.map(item => {
          if(res.accountPermission[visibleType] === 'ALL') {
            item.selected = true
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                item1.selected = true
              })
            }
          } else if(res.accountPermission[visibleType] === 'PART'){
            if(res.accountPermission[groupVisibleSet] && res.accountPermission[groupVisibleSet].length > 0) {
              res.accountPermission[groupVisibleSet].forEach(item2 => {
                if(item2.uid === item.uid) {
                  item.selected = true
                  if(item.children && item.children.length !== 0) {
                    item.children.map(item3 => {
                      item3.selected = true
                    })
                  }
                }
              })
            }
            if(res.accountPermission[accountVisibleSet] && res.accountPermission[accountVisibleSet].length > 0) {
              res.accountPermission[accountVisibleSet].forEach(item4 => {
                if(item.children && item.children.length !== 0) {
                  item.children.map(item5 => {
                    if(item4.uid === item5.uid) {
                      item5.selected = true
                    }
                  })
                }
              })
              let flag = false
              item.children.forEach(item6 => {
                if(! item6.selected) {
                  flag = true
                }
              })
              if(flag) {
                item.isIndeterminate = true
                item.selected = false
              } else {
                item.isIndeterminate = false
                item.selected = true
              }
            }
          }
        })
        data.map(item => {
          if(!item.selected) {
            item.selected = false
          }
          if(item.children && item.children.length !== 0) {
            item.children.map(item1 => {
              if(!item1.selected) {
                item1.selected = false
              }
            })
          }
        })
        console.log('after', data)
        this.defaultContent = data
        this.showDialogMultipleTree = true
      })
    },
    changeSelected(evt, data, key) {
      console.log(evt, data, key)
      console.log(this.defaultContent)
      if(data.nodeType === 'GROUP') {
        this.defaultContent.map(item => {
          if(item.uid === data.uid) {
            item.selected = evt
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                item1.selected = evt
              })
            }
          }
        })
      }
      if(data.nodeType === 'ACCOUNT') {
        this.defaultContent.map(item => {
          if(item.children && item.children.length !== 0) {
            item.children.map(item1 => {
              if(item1.uid === data.uid) {
                item1.selected = evt
              }
            })
          }
        })
      }
      let flag = false
      this.defaultContent.map(item => {
        item.children.forEach(item2 => {
          if (!item2.selected) {
            flag = true
          }
        })
        if (flag) {
          item.isIndeterminate = true
          item.selected = false
        } else {
          item.isIndeterminate = false
          item.selected = true
        }
        if(!evt && data.nodeType === 'GROUP') {
          item.isIndeterminate = false
        }
      })
    },

    changeContactsVisible() {
      let type = this.currentVisibleType
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      this.accountPermissionData[accountVisibleSet] = []
      this.accountPermissionData[groupVisibleSet] = []
      this.defaultContent.forEach(item => {
        if(! item.selected) {
          this.accountPermissionData[visibleType] = 'PART'
          if(item.children && item.children.length !== 0) {
            item.children.forEach(item1 => {
              if(item1.selected) {
                this.accountPermissionData[accountVisibleSet].push({uid: item1.uid, name: item1.name})
              }
            })
          }
        } else {
          this.accountPermissionData[groupVisibleSet].push({uid: item.uid, name: item.name})
        }
      })
      if(this.accountPermissionData[groupVisibleSet].length === 0) {
        this.accountPermissionData[groupVisibleSet]= null
      }
      if(this.accountPermissionData[accountVisibleSet].length === 0) {
        this.accountPermissionData[accountVisibleSet] = null
      }
      if(this.accountPermissionData[visibleType] === 'ALL') {
        this.accountPermissionData[groupVisibleSet]= null
        this.accountPermissionData[accountVisibleSet] = null
      }
      console.log(this.accountPermissionData)
      this.$nextTick(() => {
        this.formData.dataSet = this.getVisibleText('dataSet')
        this.formData.algorithmEngineering = this.getVisibleText('algorithmEngineering')
        this.formData.aiApp = this.getVisibleText('aiApp')
        this.formData.realTimeMonitoring = this.getVisibleText('realTimeMonitoring')
        this.formData.report = this.getVisibleText('report')
        this.formData.dataAnalysis = this.getVisibleText('dataAnalysis')
      })
      this.showDialogMultipleTree = false
    },

    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.targetAccount = this.tableRow.name + ' ' + this.tableRow.accountId
          this.formData.groupName = this.tableRow.group.name
          let pk = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('aiAccountPermissionManage.notHave')
          }

          this.accountPermissionData = this.tableRow.accountPermission

          this.formData.dataSet = this.getVisibleText('dataSet')
          this.formData.algorithmEngineering = this.getVisibleText('algorithmEngineering')
          this.formData.aiApp = this.getVisibleText('aiApp')
          this.formData.realTimeMonitoring = this.getVisibleText('realTimeMonitoring')
          this.formData.report = this.getVisibleText('report')
          this.formData.dataAnalysis = this.getVisibleText('dataAnalysis')

          Object.keys(this.accountPermissionData).filter(item => item.indexOf('Enable') > -1).map(item => {
            this.formData[item] = this.accountPermissionData[item] === 'YES'
          })

        }
      })
    },

    getVisibleText(type) {
      let visibleType = type + 'VisibleType'
      let groupVisibleSet = type + 'GroupVisibleSet'
      let accountVisibleSet = type + 'AccountVisibleSet'
      let aaa = ''
      if(this.accountPermissionData[visibleType] === 'PART') {
        if(this.accountPermissionData[groupVisibleSet]) {
          this.accountPermissionData[groupVisibleSet].forEach(item => aaa += item.name + '、')
        }
        if(this.accountPermissionData[accountVisibleSet]) {
          this.accountPermissionData[accountVisibleSet].forEach(item => aaa += item.name + '、')
        }
      } else if(this.accountPermissionData[visibleType] === 'ALL' || this.accountPermissionData[visibleType] === null) {
        aaa += this.$t('aiAccountPermissionManage.allPermission') + '、'
      } else if(this.accountPermissionData[visibleType] === 'SELF') {
        aaa = this.$t('aiAccountPermissionManage.self')
      }
      return aaa.substr(0, aaa.length - 1)
    },

    // 取消
    cancel() {
      this.$emit('closePage', 'cancel1')
    },
    // 确认
    confirm() {
      this.setEnableData()
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.aiStationUpdateVisible,
        data: {
          uid: this.tableRow.uid,
          accountPermissionData: this.accountPermissionData
        },
        headers: {product_version: 'NORMAL'}
      }).then((data) => {
        this.$emit('closePage', 'confirm')
      })
    },
    setEnableData() {
      Object.keys(this.formData).filter(item => item.indexOf('Enable') > -1).map(item => {
        this.accountPermissionData[item] = this.formData[item]? 'YES' : 'NO'
      })
    }
  },
}
</script>
